import React from 'react'
import { Link } from 'gatsby'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import styled from 'styled-components'

import Card from './Card'
import ProductImage from './ProductImage'
import cleanUpPath from '../utils/clean-up-path'
import getCategoryLink from '../utils/getCategoryLink'
import { mediaQuery } from '../utils/media-queries'

const Wrapper = styled.article`
    padding: 1rem 0;
    position: relative;

    ${mediaQuery.isBig`
        padding: 2rem;
    `}

    a {
        color: var(--text-color);
        text-decoration: none;
        display: flex;
        flex-direction: column-reverse;

        ${mediaQuery.isBig`
            display: block;
        `}

        &:hover .image-wrapper {
            bottom: 2rem;
        }
    }

    h3 {
        color: ${props => props.theme.brandColor2};
        font-size: 1.2rem;
        margin-bottom: 0.5rem;

        text-align: center;

        ${mediaQuery.isBig`
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        `}
    }

    ul {
        display: none;

        ${mediaQuery.isBig`
            display: block;
            list-style-position: inside;
            list-style: none;
            margin: 0;
            padding: 0;
        `}
    }

    li {
        margin: 0;
        font-size: 0.9rem;
    }

    .image-wrapper {
        transition: bottom 150ms ease-in-out;

        ${mediaQuery.isBig`
            position: absolute;
            right: 1rem;
            bottom: 1rem;
        `}
    }
`

const HomeCategoryItem = ({ name, store, image, items }, i) => {
    const link = getCategoryLink(name, store)
    const category = cleanUpPath(name)
    return (
        <Card key={i}>
            <Wrapper>
                <Link
                    onClick={() => {
                        trackCustomEvent({
                            category: 'Home Category Menu',
                            action: 'Click',
                            label: name,
                        })
                    }}
                    activeClassName="active"
                    to={link}
                >
                    <h3>{name}</h3>
                    <ul>
                        {items.map((item, k) => {
                            return <li key={k}>{item}</li>
                        })}
                    </ul>
                    {image && (
                        <div className="image-wrapper">
                            <ProductImage
                                category={category}
                                name={image}
                                size={119}
                            />
                        </div>
                    )}
                </Link>
            </Wrapper>
        </Card>
    )
}

export default HomeCategoryItem
