function cleanUpPath (text) {
    return text
        .replace(/á/gi, 'a')
        .replace(/é/gi, 'e')
        .replace(/í/gi, 'i')
        .replace(/ó/gi, 'o')
        .replace(/ú/gi, 'u')
        .replace(/ñ/gi, 'n')
        .replace(/ñ/gi, 'n')
        .replace(/\//gi, ' ')
        .replace(/%/gi, '')
}

module.exports = cleanUpPath
