import React from 'react'
import styled from 'styled-components'
import { mediaQuery } from '../utils/media-queries'
import HomeCategoryItem from './HomeCategoryItem'

const Wrapper = styled.section`
    --box: 130px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--box), 1fr));
    grid-gap: 1rem;
    margin: 1rem var(--spacing-global);

    ${mediaQuery.isBig`
        --box: 300px;
    `}
`

const HomeCategoryGrid = ({ categories, store }) => {
    return (
        <Wrapper>
            {categories.map((item, i) => {
                const props = {
                    ...item,
                    store,
                }
                return <HomeCategoryItem {...props} key={i} />
            })}
        </Wrapper>
    )
}

export default HomeCategoryGrid
