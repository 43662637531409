import React from 'react'
import styled from 'styled-components'

import HomeCategoryGrid from '../../components/HomeCategoryGrid'
import HowToBuy from '../../components/HowToBuy'
import StoreLayout from '../../components/StoreLayout'
import categories from '../../utils/StoreHomeCategories'
import getStoreFromPath from '../../utils/getStoreFromPath'
import { ButtonLink } from '../../components/styled/Button'
import { Link } from 'gatsby'

const Main = styled.main`
    margin: 1rem 0;
`

const Header = styled.div`
    margin: 0 var(--spacing-global);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;

    h1 {
        margin-bottom: 0.5rem;
    }
`

const Ad = styled.div`
    margin: 0 var(--spacing-global);
    border: solid 2px ${props => props.theme.brandColor1};
    text-align: center;
    padding: 0.5rem;


    h3 {
        margin: 0;
    }
`

const Actions = styled.div`
    a {
        margin-right: 0.5rem;
    }
`

const EmersonPage = ({ location }) => {
    const store = getStoreFromPath(location.pathname)

    return (
        <StoreLayout
            hideCategories={true}
            ContentComponent={Main}
            seotitle="Emerson"
            seourl={location.pathname}
            location={location}
        >
            <Header>
                <h1>Emerson</h1>

                <Actions>
                    <ButtonLink to="/emerson/mispedidos">
                        Mis Pedidos
                    </ButtonLink>
                    <HowToBuy />
                </Actions>
            </Header>

            {/* <Ad>
                <h3>
                    Todos los almuerzos de Pimiendo Rosa tienen un 10% de
                    descuento. <Link to="/emerson/comida-preparada">Compra el tuyo</Link>
                </h3>
            </Ad> */}

            <HomeCategoryGrid categories={categories} store={store} />
        </StoreLayout>
    )
}

export default EmersonPage
