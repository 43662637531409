export default [
    {
        name: 'Bebidas',
        items: ['Gaseosas', 'Té Frío', 'Jugos Naturales', 'Agua', 'Café Frío'],
        image: 'Femsa_Coca_Cola_Lata_Zero_354ml',
    },
    {
        name: 'Repostería',
        items: ['Empanadas', 'Cangrejos', 'Dulces', 'Queque', 'Ceres'],
        image: 'Walter_Empanadas_Carne_Mechada',
    },
    {
        name: 'Chocolates',
        items: ['Hersheys', 'M&M', 'Chocolito', 'Snickers', 'Choys'],
        image: 'Hersheys_Milk_Chocolate_Barra_43g',
    },
    {
        name: 'Salados',
        items: ['Pizzerola', 'Pro', 'Sanissimo', 'Ranchitas', 'Chicharritos'],
        image: 'Jacks_Picaritas_30g_Bbq',
    },
    {
        name: 'Healthy',
        items: ['Bioland', 'NutriSnacks', 'Bizcochos', 'Palitos', 'Bruschette'],
        image: 'Biscolite_Rosquillas_100_Gr',
    },
    {
        name: 'Helados',
        items: [
            'Choco Bigger',
            'Choco Snack',
            'Trits',
            'Krunchy',
            'Mini Sandwich',
        ],
        image: 'Charo_Chocolin',
    },
    {
        name: 'Comida Preparada',
        items: ['Burritos', 'Ensaladas', 'Casados', 'Sandwich', 'Pupusas'],
        image: 'Pimiento_Rosa_Pastas_Varias',
    },
    {
        name: 'Dulces y Galletas',
        items: ['Brownie', 'Cajetas', 'Pozuelo', 'Chikys', 'Bimbo'],
        image: 'Pozuelo_Chiky_Chocolate',
    },
    {
        name: 'Chiclets y Caramelos',
        items: ['Halls', 'Tridents', 'Popys', 'Cerezas', 'Perlas'],
        image: 'Trident_Menta_6pz',
    },
    {
        name: 'Lacteos',
        items: ['Yogurt', 'FrescoLeche', 'Leche', 'Natilla', 'Griegos'],
        image: 'Dos_Pinos_Deligurt_In_Line_Arandanos_200ml',
    },
    {
        name: 'Medicinas',
        items: ['Tabcin', 'Curitas', 'Dorival', 'Acetaminofen', 'Panadol'],
        image: 'Panadol_Ultra_2_Unid',
    },
    {
        name: 'Higiene Personal',
        items: [
            'Crema dental',
            'Cepillos dentales',
            'Desodorante',
            'Toallas',
            'Hilo Dental',
        ],
        image: 'Crema_Dental_Colgate_Anticaries_22ml',
    },
]
