import React, { useState } from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Dialog from './Dialog'
import DialogHeader from './styled/DialogHeader'
import Button from './styled/Button'

const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`

const Item = styled.li`
    display: flex;
    border-bottom: solid 1px #ccc;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    .gatsby-image-wrapper {
        flex-shrink: 0;
        margin-right: 1rem;
    }
`

const ButtonWrapper = styled.div`
    text-align: center;
`

const HowToBuy = () => {
    const [open, setOpen] = useState(false)
    return (
        <StaticQuery
            query={graphql`
                query {
                    step1: file(relativePath: { eq: "help/001.jpg" }) {
                        childImageSharp {
                            fixed(width: 115) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                    step2: file(relativePath: { eq: "help/002.jpg" }) {
                        childImageSharp {
                            fixed(width: 115) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                    step3: file(relativePath: { eq: "help/003.jpg" }) {
                        childImageSharp {
                            fixed(width: 115) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                    step4: file(relativePath: { eq: "help/004.jpg" }) {
                        childImageSharp {
                            fixed(width: 115) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            `}
            render={data => {
                return !open ? (
                    <Button
                        outline="true"
                        onClick={() => {
                            setOpen(true)
                        }}
                    >
                        ¿Como Comprar?
                    </Button>
                ) : (
                    <Dialog
                        onDialogClick={() => {
                            setOpen(false)
                        }}
                    >
                        <DialogHeader>
                            <h3>¿Como comprar?</h3>
                        </DialogHeader>
                        <List>
                            <Item>
                                <Img fixed={data.step1.childImageSharp.fixed} />
                                <p>
                                    Agrega productos, puedes buscar por
                                    categoría o por nombre en el <b>buscador</b>
                                    .
                                </p>
                            </Item>
                            <Item>
                                <Img fixed={data.step2.childImageSharp.fixed} />
                                <p>
                                    Click en el botón de checkout, verifica tu
                                    pedido y click <b>“Hacer Pedido”</b>.
                                </p>
                            </Item>
                            <Item>
                                <Img fixed={data.step4.childImageSharp.fixed} />
                                <p>
                                    Ingresa tus datos, presiona enviar y listo.
                                    Busca tus productos en <b>15 minutos</b>.
                                </p>
                            </Item>
                        </List>
                        <ButtonWrapper>
                            <Button
                                onClick={() => {
                                    setOpen(false)
                                }}
                            >
                                Cerrar
                            </Button>
                        </ButtonWrapper>
                    </Dialog>
                )
            }}
        />
    )
}

export default HowToBuy
